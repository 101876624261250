<template>
  <BaseList
    :list-query="list"
    :name-map="nameMap"
    route="herSwedenParishDetail"
    locale-section="pages.herSwedenParish"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HerSwedenParishList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: herSwedenParishes {
            id
            parishCode
            raaParish
          }
        }
      `
    };
  },
  methods: {
    nameMap(element) {
      return {
        name: element.raaParish + " (" + element.parishCode + ")",
        ...element
      };
    }
  }
};
</script>
